import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledRemoteStoreBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [34, 34, 54, 54]
  })
)

export const styledRemoteStore = css`
  ${styledRemoteStoreBps};
`

export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    letterSpacing: [0.25],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const H5Bps = css(
  mq({
    marginTop: [40, 30, 30, 30],
    marginBottom: [30]
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [16],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const plansItemsBps = css(
  mq({
    marginLeft: [0, 136, 24, 126],
    marginRight: [0, 136, 24, 126]
  })
)

export const lineBorderBps = css(
  mq({
    width: [328, 422, 1032, 1032],
    marginLeft: [0, 89, 200, 302],
    marginRight: [0, 89, 200, 302]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
    display: grid;
  }
  p {
    ${PHeaderBps};
  }
  h5 {
    ${H5Bps};
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
  }
`
export const plansItems = css`
  display: flex;
  margin: 0 auto;
  justify-content: center;
`

export const planItem = css`
  width: 328px;
  height: 398px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  margin-bottom: 16px;
  h3 {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    justify-content: center;
    width: 296px;
    font-weight: 500;
    margin: 0 16px;
    padding-top: 21.5px;
    padding-bottom: 23.5px;
    span {
      font-size: 13.8px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.74;
      letter-spacing: 0.1px;
      text-align: center;
      color: #666666;
    }
  }
  h5 {
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    width: 296px;
    margin: 0 16px;
    padding-top: 50px;
  }
  h6 {
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    font-weight: 600;
    width: 272px;
    margin: 23.5px 28px;
  }
  p {
    text-align: center;
    width: 296px;
    margin: 0 16px;
    padding-bottom: 23.5px;
  }
`

export const line = css`
  width: 50px;
  height: 1px;
  border-bottom: solid 4px #f3f5f5;
  margin: 0 auto;
`
export const lineBorder = css`
  ${lineBorderBps};
  height: 1px;
  border-bottom: solid 1px rgba(217, 222, 227, 0.5);
`
export const serviceList = css`
  display: flex;
  justify-content: center;
  div:nth-child(2n) {
    margin-left: 8px;
    margin-right: 8px;
  }
`
export const serviceItem = css`
  color: #f9fafa;
  height: 56px;
  font-weight: 600;
  font-size: 15.7px;
  letter-spacing: normal;
  line-height: 1.53px;
  background-color: #7f22fd;
  border-radius: 28px;
  outline: none;
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: center;
`