import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, navigate }  from 'gatsby'
import { Box } from '@rebass/grid/emotion'


import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Hr from '../components/Hr'
import H3 from '../components/H3'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Paragraph from '../components/Paragraph'
import Subtitle from '../components/SubTitle2'
import Div from './../components/Div'
import ds from '../theme'
import { css } from '@emotion/core'

import { Container, mq } from '../components/styles/Layout.styles'
import Button from '../components/Button/Button'
import { 
  styledRemoteStore,
  header,
  serviceList,
  serviceItem
} from '../components/styles/RemoteStore.styles'
import { 
  currency,
  item
} from '../components/styles/DedicatedServersPrice.styles'

const ProductItem = ({
  productName,
  productAdvantages,
  productPrice,
  productDescription,
  productBtn,
}) => {
  return (
    <div
      style={{
        padding: '24px 16px 40px 16px',
        backgroundColor: '#ffffff',
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 12,
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        item 
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <H5
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 8,
            width: 296,
            textAlign: 'center'
          }}
        >
          {productName}
        </H5>
        <Subtitle
          style={{
            fontSize: 13.8,
            fontWeight: 600,
            lineHeight: 1.4,
            letterSpacing: 0.1,
            textAlign: 'center',
            color: 'rgba(0, 0, 0, 0.6)',
            width: 195,
            whiteSpace: 'pre-wrap'
          }}
        >
          {productAdvantages}
        </Subtitle>
        <Div 
          style={{
            width: 50,
            height: 1,
            borderBottom: 'solid 4px #f3f5f5',
            margin: '20px 0'
          }}
        />
        <H3
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 8,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <span css={currency}>R$</span>
          {productPrice}
          <span css={currency}>,90</span>
        </H3>
        <Div 
          style={{
            width: 50,
            height: 1,
            borderBottom: 'solid 4px #f3f5f5',
            margin: '20px 0'
          }}
        />
        <H6 
          style={{
            fontSize: 19.6,
            textAlign: 'center',
            fontWeight: 600,
            color: 'rgba(0, 0, 0, 0.87)',
            marginBottom: 24
          }}>
          {productDescription}
        </H6>
        <Button
          onClick={() => navigate('/contatos')}
          schema='white'
          outlined
          selected
          variant='base'
          on='onSurface'
          width={['100%']}
          maxWidth={['140px']}
          style={{
            display: 'flex',
            border: '1px solid rgba(0, 0, 0, 0.12)'
          }}
        >
          {productBtn}
        </Button>
      </Div>
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledRemoteStore}>
        <Container>
          <Row
            flexDirection={['column']}
            css={header}
            mb={[30, 30, 50, 50]}
          >
            <Column
              width={[1]}
            >
              <H4>
                <span>Armazenamento remoto</span>
                Serviço de Armazenamento Remoto In-House
              </H4>
              <Paragraph>Adicionando um serviço de armazenamento remoto para o seu serviço de hosting, seus dados serão armazenados em um local remoto seguro em nosso data center, ajudando a maximizar a redundância e melhorando o desempenho global de seu servidor atual.</Paragraph>
              <Paragraph>Disponibilizamos diversas maneiras para que você possa acessar e gerenciar o seu serviço, incluindo:</Paragraph>
            </Column>
            <Column
              width={[1]}
              mt={30}
              mb={14}
            >
              <div css={serviceList} style={{ marginBottom: 16 }}>
                <Box css={serviceItem} style={{ width: 103 }}>FTP</Box>
                <Box css={serviceItem} style={{ width: 103 }}>SFTP</Box>
                <Box css={serviceItem} style={{ width: 103 }}>SCP</Box>
              </div>
              <div css={serviceList} style={{ marginBottom: 16 }}>
                <Box css={serviceItem} style={{ width: 103 }}>NFS</Box>
                <Box css={serviceItem} style={{ width: 103 }}>NFS</Box>
                <Box css={serviceItem} style={{ width: 103 }}>RSYNC</Box>
              </div>
              <div css={serviceList}>
                <Box css={serviceItem} style={{ width: 284 }}>Quota de disco expansível</Box>
              </div>
            </Column>
            <Column
              width={[1]}
            >
              <Paragraph>Com tantas opções para administrar o seu serviço de armazenamento remoto esta será uma excelente opção para recuperação de dados em casos de problemas no seu servidor atual. Você poderá montar seu serviço de armazenamento remoto como uma unidade no seu VPS ou servidor dedicado ou usar o rsync para enviar e receber os dados de suas contas.</Paragraph>
              <H5>Em caso de dúvidas</H5>
              <Button
                onClick={() => navigate('/contatos')}
                outlined
                schema='lightPeriwinkle'
                align='center'
                mb={[3]}
                style={{
                  color: '#737474',
                  fontWeight: 600,
                  letterSpacing: 1.25,
                  lineHeight: 1.16,
                  padding: '14px 16px',
                  width: 200,
                  border: 'solid 1px rgba(0, 0, 0, 0.12)',
                  outline: 'none',
                  display: 'flex',
                  margin: '0 auto',
                  justifyContent: 'center'
                }}
              >
                CONSULTE-NOS
              </Button>
            </Column>
          </Row>
          <Hr
            lineColor='lightPeriwinkle'
            maxWidth={[328, '100%', 1032, 1032]}
            style={{
              opacity: 0.5,
              margin: '0 auto'
            }}
          />
          <Row
            flexDirection={['column', 'row']}
            css={header}
            mt={[40, 40, 39.5, 39.5]}
            mb={30}
          >
            <Column
              width={[1]}
            >
              <H4>Preços dos Planos</H4>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'center'}
          >
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='25GB'
                  productAdvantages='de espaço'
                  productPrice='45'
                  productDescription='Tráfego Ilimitado'
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='50GB'
                  productAdvantages='de espaço'
                  productPrice='75'
                  productDescription='Tráfego Ilimitado'
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='100GB'
                  productAdvantages='de espaço'
                  productPrice='140'
                  productDescription='Tráfego Ilimitado'
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'center'}
          >
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='200GB'
                  productAdvantages='de espaço'
                  productPrice='200'
                  productDescription='Tráfego Ilimitado'
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='300GB'
                  productAdvantages='de espaço'
                  productPrice='350'
                  productDescription='Tráfego Ilimitado'
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Armazenamento Remoto' />
      <PageTemplate
        title='Armazenamento Remoto'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
